const peoples = document.getElementsByClassName("people__wrapper")

if(peoples) {
    Array.from(peoples).forEach((children) =>{
        const arrowRight = children.getElementsByClassName("arrow")
        const arrowLeft = children.getElementsByClassName("arrow-back")
        const list = children.getElementsByClassName("people__list")
        const item = children.getElementsByClassName("person")


        let scroll = 0
        const arrowF = Array.from(arrowRight)
        const arrowB = Array.from(arrowLeft)
        const nodes = Array.from(list)
        const person = Array.from(item)

        arrowF.map((arrow, key) => {
            console.log(nodes[key].offsetWidth)
            console.log(nodes[key].scrollWidth)
            console.log(person[key].scrollWidth)

            arrow.addEventListener('click', () =>{
                if(nodes[key].offsetWidth < nodes[key].scrollWidth)
                    scroll += person[key].offsetWidth + 32

                nodes[key].scroll({
                    top:0,
                    left:scroll,
                    behavior: 'smooth'
                })
                console.log(nodes[key].offsetWidth)
                console.log(nodes[key].scrollWidth)
                console.log(person[key].scrollWidth)
                console.log(scroll)

                if(scroll + nodes[key].offsetWidth >= nodes[key].scrollWidth)
                    arrow.classList.add('end')

                if(scroll != 0)
                    arrowLeft[0].classList.remove('end')

            })

            /* TODO ArrowLeft accessibility */

        })

        arrowB.map((arrow, key) => {
            console.log(nodes[key].offsetWidth)
            console.log(nodes[key].scrollWidth)
            console.log(person[key].scrollWidth)

            arrow.addEventListener('click', () =>{
                if(scroll > 0)
                    scroll -= person[key].offsetWidth + 32

                nodes[key].scroll({
                    top:0,
                    left:scroll,
                    behavior: 'smooth'
                })
                console.log(nodes[key].offsetWidth)
                console.log(nodes[key].scrollWidth)
                console.log(person[key].scrollWidth)
                console.log(scroll)

                if(scroll == 0)
                    arrow.classList.add('end')

                if(scroll + nodes[key].offsetWidth <= nodes[key].scrollWidth)
                    arrowRight[0].classList.remove('end')
            })

            /* TODO ArrowRight accessibility */

        })
    })
}
