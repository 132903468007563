//console.log('test')
import '@/css/app.scss'

import '@js/components/button.js'
import '@js/components/people-scroll.js'
import '@js/components/slideshow-scroll.js'

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR")
    });
}
console.log('Hello!')