const slideshow = document.getElementsByClassName("image-slideshow__wrapper")

if(slideshow) {
    Array.from(slideshow).forEach((children) =>{
        const arrowRight = children.getElementsByClassName("arrow")
        const arrowLeft = children.getElementsByClassName("arrow-back")
        const grid = children.getElementsByClassName("slideshow-grid")
        const showSlide = children.getElementsByClassName("js-slide")
        const list = children.getElementsByClassName("slideshow__list")
        const slide = children.getElementsByClassName("slideshow__wrapper")
        const item = children.getElementsByClassName("slideshow-image")
        const showGrid = children.getElementsByClassName("js-grid")
        console.log(showGrid)

        let scroll = 0
        const arrowF = Array.from(arrowRight)
        const arrowB = Array.from(arrowLeft)
        const nodes = Array.from(list)
        const image = Array.from(item)
        // const slideshow = Array.from(slide)
        // const gridBtn = Array.from(showGrid)
        // console.log(gridBtn)

        showGrid[0].addEventListener('click', () =>{
            slide[0].classList.add('hidden')
            grid[0].classList.remove('hidden')
            showGrid[0].classList.remove('open')
        })
        showSlide[0].addEventListener('click', () =>{
            grid[0].classList.add('hidden')
            slide[0].classList.remove('hidden')
            showGrid[0].classList.add('open')
        })

        arrowF.map((arrow, key) => {
            console.log(nodes[key].offsetWidth)
            console.log(nodes[key].scrollWidth)
            console.log(image[key].scrollWidth)

            arrow.addEventListener('click', () =>{
                if(nodes[key].offsetWidth < nodes[key].scrollWidth)
                    scroll += image[key].offsetWidth

                nodes[key].scroll({
                    top:0,
                    left:scroll,
                    behavior: 'smooth'
                })
                console.log(nodes[key].offsetWidth)
                console.log(nodes[key].scrollWidth)
                console.log(image[key].scrollWidth)
                console.log(scroll)

                if(scroll + nodes[key].offsetWidth >= nodes[key].scrollWidth)
                    arrow.classList.add('end')

                if(scroll != 0)
                    arrowLeft[0].classList.remove('end')

            })

            /* TODO ArrowLeft accessibility */

        })

        arrowB.map((arrow, key) => {
            console.log(nodes[key].offsetWidth)
            console.log(nodes[key].scrollWidth)
            console.log(image[key].scrollWidth)

            arrow.addEventListener('click', () =>{
                if(scroll > 0)
                    scroll -= image[key].offsetWidth

                nodes[key].scroll({
                    top:0,
                    left:scroll,
                    behavior: 'smooth'
                })
                console.log(nodes[key].offsetWidth)
                console.log(nodes[key].scrollWidth)
                console.log(image[key].scrollWidth)
                console.log(scroll)

                if(scroll == 0)
                    arrow.classList.add('end')

                if(scroll + nodes[key].offsetWidth <= nodes[key].scrollWidth)
                    arrowRight[0].classList.remove('end')
            })

            /* TODO ArrowRight accessibility */

        })
    })
}
