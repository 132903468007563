const button = document.querySelector('.js-menu')
const headerBottom = document.querySelector('.js-close')
const navButtons = document.querySelector('.js-navButtons-mobile')
const primaryNav = document.querySelector('.primary-nav')
const header = document.querySelector('.header-wrapper')

button.addEventListener('click', function(){
    if(headerBottom.classList.contains('js-close')) {
        headerBottom.classList.remove('js-close');
        headerBottom.classList.add('js-open');
        headerBottom.classList.add('open');
        header.classList.add('open')
    } else {
        headerBottom.classList.add('js-close');
        headerBottom.classList.remove('js-open');
        headerBottom.classList.remove('open');
        header.classList.remove('open')
    }
    if(primaryNav.classList.contains('max-[859px]:hidden')) {
        primaryNav.classList.remove('max-[859px]:hidden');
    } else {
        primaryNav.classList.add('max-[859px]:hidden');
    }
})